import React, { Component } from "react"
import Layout from "../components/Layout/layout"

class GalleryPage extends Component {
  render() {
    let galleryData = this.props.data.allGalleryJson.nodes[0]

    return (
      <Layout page={"gallery"} title={galleryData.title}>
        <p className="text">{galleryData.text}</p>
      </Layout>
    )
  }
}
export default GalleryPage

export const query = graphql`
  query {
    allGalleryJson {
      nodes {
        id
        title
        description
        text
      }
    }
  }
`
